import React, {useCallback, useMemo, useRef, useState} from 'react';
import './App.css';
import './spinner.css';
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, {type Crop, PercentCrop, PixelCrop} from 'react-image-crop'

// eslint-disable-next-line no-template-curly-in-string
const api_url = '${API_URL}'.startsWith('http') ? '${API_URL}' : 'http://127.0.0.1:5000'


const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const resizeImage = (img: HTMLImageElement, x: number, y: number, w: number, h: number, width: number) => {
    const canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d")!,
        oc = document.createElement('canvas'),
        octx = oc.getContext('2d')!;

    // Crop
    oc.width = Math.floor(w * 0.5);
    oc.height = Math.floor(h * 0.5);

    octx.drawImage(img, x, y, w, h, 0, 0, oc.width, oc.height);

    // Resize
    let cur = {
        width: oc.width,
        height: oc.height
    }

    while (cur.width * 0.5 > width) {
        cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
        };
        octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
    }

    // Draw to destination canvas
    canvas.width = width;
    canvas.height = canvas.width * h / w;
    ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
}


function App() {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [maskImage, setMaskImage] = useState<string>();
    const [filteredMaskImage, setFilteredMaskImage] = useState<string>();
    const [crop, setCrop] = useState<Crop>();
    const [loading, setLoading] = useState(0);
    const [numberOfPlates, setNumberOfPlates] = useState(null);
    const imRef = useRef(null);
    const abortController = useRef<AbortController | null>(null);

    const selectedImageUrl = useMemo(
        () => selectedImage != null
            ? URL.createObjectURL(selectedImage)
            : '',
        [selectedImage]);

    const handleCropCompleted = useCallback((x: PixelCrop, percentCrop: PercentCrop) => {
        if (selectedImage == null) return;

        (async () => {
            abortController.current?.abort();
            abortController.current = new AbortController();

            setLoading(x => x + 1);
            const img: HTMLImageElement = imRef.current!;

            const x = Math.floor(percentCrop.x * img.naturalWidth / 100);
            const y = Math.floor(percentCrop.y * img.naturalHeight / 100);
            const w = Math.floor(percentCrop.width * img.naturalWidth / 100);
            const h = Math.floor(percentCrop.height * img.naturalHeight / 100);

            try {
                const response = await fetch(api_url + '/unet_count', {

                    body: JSON.stringify({
                        "image_base64": resizeImage(img, x, y, w, h, 640)
                    }),
                    method: "POST",
                    signal: abortController.current!.signal,
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                if (!response.ok) {
                    console.error(response, response.text());
                    return;
                }

                const body = await response.json();
                setNumberOfPlates(body["count"]);
                setMaskImage(body["mask_image_base64"])
                setFilteredMaskImage(body["mask_filtered_base64"])
            } catch (e) {
                setNumberOfPlates(null);
                setMaskImage("")
                setFilteredMaskImage("")
            } finally {
                setLoading(x => x - 1);
            }
        })();

        return () => abortController.current?.abort();
    }, [selectedImage]);

    return (
        <div className="app">
            <header>
                <h1>PHE Inspector</h1>
                <span className={"logo-div"}>
                    Created by {" "}
                    <img className={"logo"} src={"./logo.svg"} alt={"Logo of digatus.ai"}/>
                </span>
            </header>
            <main>
                <div className={"notes"}>
                    <p className={"notes-header"}>
                        Instructions
                    </p>
                    <p className={"notes-body"}>
                        Select a portion of the image that contains the plate heat exchanger.<br/>
                        Optimally, select a section where only the plates and a small portion of the frame are visible.
                    </p>
                </div>
                <label htmlFor="image" className={"file-upload"}>
                    {selectedImage != null ? `✅ Image selected: ${selectedImage.name}` : 'Select a file'}
                </label>
                <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={(event) => {
                        setSelectedImage(event.target.files?.[0] || null);
                    }}
                />
                <div className={"metrics"}>
                    <div className={"metric-card"}>
                        <div className={"metric-number"}>{loading
                            ? <div className="lds-facebook">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            : (numberOfPlates || '--')}</div>
                        <p className={"metric-text"}>Plates detected</p>
                    </div>
                    {/*<div className={"metric-card"}>*/}
                    {/*    <p className={"metric-number"}>{Math.round(crop?.width || 0)}x{Math.round(crop?.height || 0)}</p>*/}
                    {/*    <p className={"metric-text"}>Crop size (w x h)</p>*/}
                    {/*</div>*/}
                </div>
                <section>
                    <h2>Plate heat exchanger image</h2>
                    {
                        (selectedImageUrl?.length || 0) > 0
                            ? <ReactCrop crop={crop} onComplete={handleCropCompleted} onChange={c => setCrop(c)}>
                                <img src={selectedImageUrl}
                                     ref={imRef}
                                     alt={'Image of PHE'}/>
                            </ReactCrop>
                            : <div className={"notes"}>
                                <p className={"notes-body"}>
                                    Please select an image.
                                </p>
                            </div>
                    }
                </section>
                <section>
                    <h2>Segmentation mask</h2>
                    {
                        (maskImage?.length || 0) > 0
                            ? <img className={"mask-image"}
                                   src={"data:image/bmp;charset=utf-8;base64," + maskImage}
                                   alt={"Mask after segmentation"}/>
                            : <div className={"notes"}>
                                <p className={"notes-body"}>
                                    Please select an image and choose a region.
                                    If this message still shows up, please reselect the region or try another region.
                                </p>
                            </div>
                    }
                </section>
                <section>
                    <h2>Filtered Segmentation mask</h2>
                    {
                        (maskImage?.length || 0) > 0
                            ? <img className={"mask-image"}
                                   src={"data:image/bmp;charset=utf-8;base64," + filteredMaskImage}
                                   alt={"Mask after segmentation"}/>
                            : <div className={"notes"}>
                                <p className={"notes-body"}>
                                    Please select an image and choose a region.
                                    If this message still shows up, please reselect the region or try another region.
                                </p>
                            </div>
                    }
                </section>
            </main>
            <footer>

            </footer>
        </div>
    );
}

export default App;
